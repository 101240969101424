<!--  -->
<template>
     <div class="cases">
      <div class="first-title">
        <div>软件定制开发案例</div>
      </div>

      <div class="swiper-box">
        <div class="mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, i) in slideImgs" :key="i">
              <img :src="item" alt="" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>


      <div class="swiper pro_swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img :src="item.src" :data-pic="item.picId">
          </div>
          <!-- <div class="swiper-slide">
            <img src="./imgs/pc/softwareCustom/medicalSystem.png" alt="" data-pic="medicalSystem">
          </div>
          <div class="swiper-slide">
            <img src="./imgs/pc/softwareCustom/e_jia.png" alt="" data-pic="e_jia">
          </div>
          <div class="swiper-slide">
            <img src="./imgs/pc/softwareCustom/medical_app.png" alt="" data-pic="medical_app">
          </div>
          <div class="swiper-slide">
            <img src="./imgs/pc/softwareCustom/culture-house.png" alt="" data-pic="culture-house">
          </div>
          <div class="swiper-slide">
            <img src="./imgs/pc/softwareCustom/electronic_info.png" alt="" data-pic="electronic_info">
          </div> -->
          <div class="swiper-slide" data-pic="">Slide 7</div>
          <div class="swiper-slide" data-pic="">Slide 8</div>
          <div class="swiper-slide" data-pic="">Slide 9</div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div class="hidden pro_detail_box">
        <div class="pro_detail swiper">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/fire-fighting.png" alt="" data-pic="fire-fighting">
            </div>
            <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/medicalSystem-1.png" alt="" data-pic="medicalSystem">
            </div>
            <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/e_jia.png" alt="" data-pic="e_jia">
            </div>
            <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/medical_app.png" alt="" data-pic="medical_app">
            </div>
            <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/culture-house.png" alt="" data-pic="culture-house">
            </div>
            <div class="swiper-slide">
              <img src="./imgs/pc/softwareCustom/electronic_info.png" alt="" data-pic="electronic_info">
            </div> -->
            <div class="swiper-slide" data-pic="">Slide 7</div>
            <div class="swiper-slide" data-pic="">Slide 8</div>
            <div class="swiper-slide" data-pic="">Slide 9</div>
          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="my-close">
          <i class="iconfont icon-cuowu"></i>
        </div>
      </div>
      
      
    </div>


    
  </template>
  
  <script>
  import "swiper/swiper-bundle.min.css";  // 所有 Swiper 样式，包括所有模块样式（如导航、分页等）
  import Swiper, { Navigation, Pagination, Autoplay } from "swiper"; // 导入您需要的模块
  export default {
    name: "HelloWorld",
      props:{
        slideImgs:{
            default:()=>[]
        }
      },
    data() {
      return {
       
      };
    },
    mounted() {
      new Swiper(".mySwiper", {
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        modules: [Navigation, Pagination, Autoplay],
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  };
  </script>
  <style>
  .swiper-box {
    position: relative;
    height: 6.3rem;
    width: 100%;
    overflow: hidden;
  }

  .swiper-pagination-bullet-active {
    background: #ffffff !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background: #ffffff !important;
    opacity: 0.5;
  }
</style>
  <style lang='scss' scoped>
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>