<!--  -->
<template>
  <div>
    <Banner :slideImgs="slideImgs"></Banner>
    <main>
    <div class="container-fluid software-type">
      <div class="first-title">
        <div>我们能提供的软件开发</div>
      </div>
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <div class="content">
            <div class="icon-large">
              <!-- <img src="./imgs/pc/softwareCustom/computer.png" alt=""> -->
              <i class="iconfont icon-icon-"></i>
            </div>
            <div class="title">电脑端软件</div>
            <p class="des">
              我们为您提供基本浏览器的（B/S架构）各类软件开发服务。从产品设计、UI设计、前端开发、后端开发、测试、项目管理，我们严格分工。
            </p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="content">
            <div class="icon-large">
              <!-- <img src="./imgs/pc/softwareCustom/phone.png" alt=""> -->
              <i class="iconfont icon-shouji"></i>
            </div>
            <div class="title">移动端APP开发</div>
            <p class="des">
              我们为您提供手机端、Pad平板端的原生开发服务，原生开发比H5开发更稳定更可靠，经过数百个成功案例的锤炼，兼容性可靠性经得住考验。
            </p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="content">
            <div class="icon-large">
              <!-- <img src="./imgs/pc/softwareCustom/wechart.png" alt=""> -->
              <i class="iconfont icon-weixin"></i>
            </div>
            <div class="title">微信端定制开发</div>
            <p class="des">
              我们为您提供基本手机版微信的公众号、小程序的各类复杂应用的开发。抱歉，我们只做复杂应用，轻量应用请绕道。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid service-process">
      <div class="first-title">
        <div>软件开发服务流程</div>
      </div>
      <div class="my-row col-11 line-right">
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-zhaoxuqiu"></i>
          </div>
          <span class="step-text">01.需求</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-weibiaoti1"></i>
          </div>
          <span class="step-text">02.分析</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-zhaobiaofangan"></i>
          </div>
          <span class="step-text">03.方案</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-queren"></i>
          </div>
          <span class="step-text">04.客户确认</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-xieyi"></i>
          </div>
          <span class="step-text">05.协议/合同</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-tiaoyanguanli"></i>
          </div>
          <span class="step-text">06.详细调研</span>
        </div>
      </div>
      <div class="my-row col-5">
        <div class="my-col">
          <div class="bgc-text">
            按需定制
          </div>
        </div>
        <div class="my-col">
          <div class="bgc-text">
            快速响应
          </div>
        </div>
        <div class="my-col">
          <div class="bgc-text">
            稳定可靠
          </div>
        </div>
        <div class="my-col">
          <div class="bgc-text">
            自由扩展
          </div>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
          <div class="icon-step">
            <i class="iconfont icon-chuangyi"></i>
          </div>
          <span class="step-text">07.详细设计</span>
          <div class="line-link"></div>
        </div>
      </div>
      <div class="my-row col-11 line-left">
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-shouhou"></i>
          </div>
          <span class="step-text">13.售后服务</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-yanshouguanli"></i>
          </div>
          <span class="step-text">12.客户验收</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-shangxian"></i>
          </div>
          <span class="step-text">11.部署/上线</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-ceshi"></i>
          </div>
          <span class="step-text">10.内部测试</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-dingzhikaifafuwu"></i>
          </div>
          <span class="step-text">09.开发</span>
        </div>
        <div class="my-col">
          <div class="line-link"></div>
        </div>
        <div class="my-col">
          <div class="icon-step">
            <i class="iconfont icon-queren"></i>
          </div>
          <span class="step-text">08.客户确认</span>
        </div>
      </div>
    </div>
    <div class="container-fluid service-process">
      <div class="first-title">
        <div>软件定制开发案例</div>
      </div>
      <div style="margin:0 auto">
        <img style="width:1400px" src="@/assets/softwareCustom/ft-banner.jpg" alt="">
      </div>
      </div>

  </main>
  </div>
</template>

<script>
import Banner from "@/components/Banner/index.vue";
import FooterSlide from './components/FooterSlide.vue'
export default {
  data() {
    return {
      slideImgs:[
      require("@/assets/softwareCustom/banner.png"),
      ],
      // footerSlideImgs:[
      //   require('@/assets/softwareDev/electric.png'),
      //   require('@/assets/softwareDev/fire-fight.png'),
      //   require('@/assets/softwareDev/e_jia.png'),
      //   require('@/assets/softwareDev/culture.png'),
      //   require('@/assets/softwareDev/medical_app.png'),
      //   require('@/assets/softwareDev/medicalSys.png'),
      // ]
    };
  },

  components: {
    Banner,
    FooterSlide
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style lang='scss' scoped>
main {
  // width: 1440px;
  padding: 15px; 
  margin: 0 auto;
  width: 1440px;
}
// 公共标题样式
.first-title {
    text-align: center;
    margin: 80px auto;

    div {
      font-size: 48px;
      position: relative;

      &::after,
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #FFFFFF;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        margin-left: 10px;
      }

      &::before {
        margin-right: 10px;
      }
    }
  }

  // 我们能提供的软件开发
  .software-type {
    .content {
      padding: 0 52px;
      text-align: center;

      .title {
        font-size: 26px;
        color: #3B93F9;
        font-weight: 500;
        margin-bottom: 29px;

        &::after,
        &::before {
          content: '';
          width: 26px;
          height: 19px;
          display: inline-block;
        }

        &::before {
          margin-right: 10px;
          background-image: url('~@/assets/softwareCustom/title-left.png');
        }

        &::after {
          margin-left: 10px;
          background-image: url('~@/assets/softwareCustom/title-right.png');
        }
      }

      .icon-large {
        .iconfont {
          font-size: 130px;
        }
      }

      .des {
        text-align: left;
        font-size: 18px;
      }
    }
  }

  // 软件开发服务流程
  .service-process {
    text-align: center;

    .my-row {
      &::after {
        content: '';
        display: block;
        clear: both;
      }

      .my-col {
        float: left;
      }
    }

    .col-11 {
      .my-col {
        width: 9%;
      }
    }

    .col-5 {
      padding-left: 50px;
      position: relative;

      .my-col {
        width: 20%;
        padding: 0 64px;
        margin-top: 90px;

        .bgc-text {
          background: #1F1D36;
          padding: 34px 45px;
          border-radius: 20px;
        }

        &:last-child {
          width: 17%;
          float: right;
          margin: 10px 0;
          padding-right: 0;
        }

        .line-link {
          height: 73px;
          transform: rotate(90deg);
        }
      }
    }

    .icon-step {
      .iconfont {
        font-size: 64px;
      }
    }

    .step-text {
      font-weight: 500;
      font-size: 18px;
    }

    .line-link {
      background-image: url('~@/assets/softwareCustom/line-pic.png');
      background-repeat: no-repeat;
      background-position: center;
    }

    .line-left,
    .line-right {
      .line-link {
        height: 22px;
        margin-top: 30px;
      }
    }

    .line-left {
      .line-link {
        transform: rotateY(180deg);
      }
    }

  }

  // 软件定制开发案例
  .cases {
    .pro_detail_box{
      position: relative;
      .my-close{
        position: absolute;
        right: -75px;
        top: 0;
        opacity: 1;
        // height: 60px;
        // width: 60px;
        .iconfont{
          font-size: 60px;
          color: #FFFFFF;
        }
      }
      .swiper-slide{
        height: auto;
      }
    }
    .hidden{
      display: none;
      // visibility:hidden
    }
    .swiper {
      width: 100%;
      height: 774px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: calc((100% - 30px) / 2);
      border-radius: 20px;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }


</style>